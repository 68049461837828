import { Toast } from "react-bootstrap"

export default function ErrorToast(props) {
	return (
		<Toast
			className={props.className}
			onClose={props.onClose}
			show={props.show}
			delay={props.delay}
			autohide
		>
			<Toast.Body>
				{props.children}
			</Toast.Body>
		</Toast>
	)
}