import { useMemo, lazy, Suspense, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Amplitude from "amplitude-js";

import { AppContext } from "../context";

export default function Result() {

	const history = useHistory();
	const { appConfig } = useContext(AppContext);
	const variant = appConfig.Result.Variant || 1;
	const stepsVariant = `OnboardingStepsVariant${localStorage.getItem("qn")}`;
	const stepsQuantity = appConfig[stepsVariant].length;

	const ResultVar = useMemo(() => lazy(() => import(`../resultVariants/Variant${variant}`)), [variant]);

	const goToPayment = () => {
		history.push("/payment");
	}

	useEffect(() => {
		if (localStorage.getItem("pv") === "9" || variant === 2) history.push("/payment");

		if (localStorage.getItem("downloadLink")) history.push("/download");
		else if (+sessionStorage.getItem("currentStep") < (stepsQuantity - 1)) history.push("/");
		else {
			Amplitude.getInstance("main").logEvent(appConfig.Result.AmplitudeEvent, appConfig.Result.AmplitudeParams);
			Amplitude.getInstance("additional").logEvent(appConfig.Result.AmplitudeEvent, appConfig.Result.AmplitudeParams);
		}
	}, [])

	return (
		<Suspense fallback={<></>}>
			<div className="mobile-view h-100">
				<ResultVar imagesHost={appConfig.Links.imagesHost} wording={appConfig.Result.wording} goToPayment={goToPayment} />
			</div>
		</Suspense>
	);
}