import Onboarding from "../pages/Onboarding";
import Welcome from "../pages/Welcome";
import Error from "../pages/Error";
import Result from "../pages/Result";
import Paygate from "../pages/Paygate";
import Download from "../pages/Download";
import Upsell from "../pages/Upsell";

export const privateRoutes = [
	{ path: "/", component: Onboarding, exact: true },
	{ path: "/welcome", component: Welcome, exact: true },
	{ path: "/error", component: Error, exact: true },
	{ path: "/result", component: Result, exact: true },
	{ path: "/payment", component: Paygate, exact: true },
	{ path: "/download", component: Download, exact: true },
	{ path: "/upsell", component: Upsell, exact: true }
];