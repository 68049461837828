import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";

import { AppContext } from "../context";
import ErrorToast from "../components/ErrorToast";
import Loader from "../components/Loader";

export default function Upsell() {

	const history = useHistory();
	const { appConfig } = useContext(AppContext);
	const [showModal, setShowModal] = useState(false);
	const [showDiscount, setShowDiscount] = useState(false);
	const [loading, setLoading] = useState(false);
	const [displayError, setDisplayError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const selectedPaymentMethod = localStorage.getItem("selectedPaymentMethod");
	const _user_token = localStorage.getItem("userToken");
	const _app_id = appConfig[appConfig.AnalyticsMode].AppID;
	const price_id = appConfig.Upsell.StripePriceID;
	const link_success = `${window.location.origin}/download`;
	const link_failure = `${window.location.origin}/error`;
	const purchase_type = appConfig.Upsell.PaypalType;
	const price = appConfig.Upsell.PaypalPrice;
	const test = appConfig.Paypal.TestMode;

	const handleDiscount = () => {
		setShowModal(false);
		setShowDiscount(true);
	}

	const handleSkip = () => {
		if (showDiscount) history.push("/download");
		else setShowModal(true);
	}

	const proceedStripe = async () => {

		setLoading(true);

		try {
			const response = await fetch(appConfig.Stripe.URL, {
				method: "POST",
				headers: { "Content-Type": "application/json", },
				body: JSON.stringify({ _user_token, _app_id, price_id })
			});

			if (response.ok) {
				const result = await response.json();
				if (result._code === 200) history.push("/download");
				else throw new Error(result._msg);
			} else throw new Error(response.statusText);

		} catch (err) {
			setLoading(false);
			setDisplayError(true);
			setErrorMessage(`${err.message}`);
			console.error(err);
		}
	}

	const proceedPaypal = async () => {

		setLoading(true);

		try {
			const result = await (
				await fetch(appConfig.Paypal.URL, {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						_user_token,
						_app_id,
						price,
						link_success,
						link_failure,
						purchase_type,
						test
					})
				})).json();

			if (result._code === 200) history.push("/download");
			else throw new Error(result._msg);

		} catch (err) {
			setLoading(false);
			setDisplayError(true);
			setErrorMessage(`${err.message}`);
			console.error(err);
		}
	}

	const proceedPayment = () => {
		if (selectedPaymentMethod === "stripe") proceedStripe();
		else if (selectedPaymentMethod === "paypal") proceedPaypal();
	}

	return (
		<Container fluid className="h-100 d-flex flex-column justify-content-center mobile-view px-4">

			{loading && <Loader />}

			<ErrorToast
				className="position-fixed top-0 w-100 mobile-view bg-danger bg-gradient rounded-0 border-0 shadow-none z-index-5 px-0"
				onClose={() => setDisplayError(false)}
				show={displayError}
				delay={10000}
				autohide
			>
				<p className="Font-Bold text-center text-white py-2 m-0">
					{errorMessage}
				</p>
			</ErrorToast>

			<Modal
				show={showModal}
				onHide={() => setShowModal(false)}
				centered
				backdrop="static"
				className="rounded-modal px-3"
			>
				<Modal.Body>
					<Container fluid className="py-3 px-0">
						<Row className="m-0">
							<Col className="px-0">
								<h1 className="Font-Bold custom-fs-1 text-center">
									Did you know?
								</h1>
								<p className="custom-fs-4 text-center opacity-80 px-2">
									QuantResearch members who start their program with our Premium Bundle has a 40% higher chance to ace the NCLEX exam
								</p>
								<p className="Font-Bold custom-fs-4 text-center opacity-80 m-0">
									40% more
								</p>
								<img src={`${appConfig.Links.imagesHost}/upsell-modal-image.png`} className="img-fluid" alt="icon" />
								<p className="custom-fs-4 text-center opacity-80 mt-3">
									We want you to be successful, so we're offering <span className="theme-text-red-60">25% off</span> the Premium Bundle, just for you!
								</p>
								<Button
									className="Font-Semibold theme-bg-primary custom-fs-4 border-0 rounded-20 w-100 py-3"
									onClick={handleDiscount}
								>
									Continue
								</Button>
							</Col>
						</Row>
					</Container>
				</Modal.Body>
			</Modal>

			<Container fluid className="py-5 px-0">
				<Row className="pt-4 m-0">
					<Col className="px-0">
						<h1 className="Font-Black custom-fs-1 text-center">
							Get extra help with our Premium Bundle
						</h1>
						<p className="custom-fs-4 text-center opacity-80">
							This is an exclusive offer, just for you, to help you achieve your goals
						</p>
					</Col>
				</Row>

				<Container fluid className="bg-white rounded-5 shadow-md py-4 px-3">
					<Row className="m-0">
						<Col className="px-4">
							<h2 className="Font-Bold custom-fs-3 text-center m-0">
								What your premium bundle will include:
							</h2>
						</Col>
					</Row>

					<Row className="pt-2 m-0">
						<Col xs="auto" className="px-0">
							<img src={`${appConfig.Links.imagesHost}/upsell-icon-1.svg`} className="img-fluid" alt="icon" />
						</Col>
						<Col className="d-flex align-items-center px-3">
							<p className="custom-fs-4 m-0">
								Nursing Cram Sheet
							</p>
						</Col>
					</Row>

					<Row className="pt-3 m-0">
						<Col xs="auto" className="px-0">
							<img src={`${appConfig.Links.imagesHost}/upsell-icon-2.svg`} className="img-fluid" alt="icon" />
						</Col>
						<Col className="d-flex align-items-center px-3">
							<p className="custom-fs-4 m-0">
								NCLEX Study Guide
							</p>
						</Col>
					</Row>

					<hr className="theme-bg-primary-20" style={{ height: "2px" }} />

					<Row className="m-0">
						<Col className="px-0">
							<p className="Font-Bold theme-text-primary text-center custom-fs-4 mb-1">
								One-time price of {showDiscount ? "$36" : "$49"}
							</p>
							<p className="text-center custom-fs-4 opacity-80 m-0">
								Original price is $90. Save {showDiscount ? "60%" : "46%"}
							</p>
						</Col>
					</Row>

					<hr className="theme-bg-primary-20" style={{ height: "2px" }} />

					<Row className="m-0">
						<Col className="px-0">
							<p className="custom-fs-4 opacity-80 text-center m-0">
								These plans are <span className="Font-Bold">yours to keep</span> even if you decide QuantResearch isn't right for you.
							</p>
						</Col>
					</Row>

				</Container>

				{showDiscount && <>
					<Row className="theme-bg-yellow-70 border border-2 theme-border-yellow rounded-5 py-3 ps-3 pe-4 mt-3 m-0">
						<Col xs="auto" className="position-relative px-0">
							<div className="position-absolute" style={{ marginTop: "-1.5rem" }}>
								<div className="theme-bg-yellow pt-2">
									<p className="Font-Semibold custom-fs-4 text-center m-0" style={{ lineHeight: "1.2em" }}>
										25%<br />OFF
									</p>
								</div>
								<div className="triangle-down" />
							</div>
						</Col>
						<Col className="px-0">
							<p className="custom-fs-4 text-end m-0">
								Additional 25% discount applied!
							</p>
						</Col>
					</Row>

					<Row className="pt-3 m-0">
						<Col className="px-0">
							<p className="Font-Bold custom-fs-3 theme-text-primary text-center m-0">
								Try it out for only <span className="text-decoration-line-through">$48</span> $36
							</p>
						</Col>
					</Row>
				</>}

				<Row className="pt-4 m-0">
					<Col className="px-0">
						<Button
							className="Font-Semibold theme-bg-primary custom-fs-4 border-0 rounded-20 w-100 py-3"
							onClick={proceedPayment}
						>
							Buy Now
						</Button>
					</Col>
				</Row>

				<Row className="pt-2 m-0">
					<Col className="px-0">
						<Button
							variant="link"
							className="Font-Semibold text-decoration-none theme-text-primary text-center w-100 custom-fs-4"
							onClick={handleSkip}
						>
							Skip this and continue enrollment
						</Button>
					</Col>
				</Row>

				<Row className="pt-2 m-0">
					<Col className="px-0">
						<p className="custom-fs-6 opacity-80 text-center m-0">
							* Your account will be charged immediately for the add-on services or producrs selected. Add-on items do not come with a free trial. Sales tax included in order total.
						</p>
					</Col>
				</Row>

			</Container>

		</Container >
	);
}