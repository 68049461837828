import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Authentification from "./components/Authentification";
import AppRouter from "./components/AppRouter";
import { AppContext } from "./context";

export default function App() {

	const appConfig = document._CNFG_;
	const urlParams = new URLSearchParams(window.location.search);
	const availablePaygates = appConfig.Payment.AvailablePaygates;
	const savedPaygate = localStorage.getItem("pv");

	if (!sessionStorage.getItem("currentStep"))
		sessionStorage.setItem("currentStep", 0);

	if (urlParams.get("pv") && availablePaygates.length > 1 && availablePaygates.includes(+urlParams.get("pv")))
		localStorage.setItem("pv", urlParams.get("pv"));
	else if (!savedPaygate || !availablePaygates.includes(+savedPaygate))
		localStorage.setItem("pv", availablePaygates[0]);

	if (urlParams.get("qn") && `OnboardingStepsVariant${urlParams.get("qn")}` in appConfig)
		localStorage.setItem("qn", urlParams.get("qn"));
	else if (!localStorage.getItem("qn"))
		localStorage.setItem("qn", 1);

	if (urlParams.get("course_id")) localStorage.setItem("course_id", urlParams.get("course_id"));

	if (urlParams.get("channel")) localStorage.setItem("channel", urlParams.get("channel"));
	if (urlParams.get("campaign")) localStorage.setItem("campaign", urlParams.get("campaign"));
	if (urlParams.get("adgroup")) localStorage.setItem("adgroup", urlParams.get("adgroup"));
	if (urlParams.get("feature")) localStorage.setItem("feature", urlParams.get("feature"));

	return (
		<AppContext.Provider value={{ appConfig }}>
			<BrowserRouter>
				<Authentification>
					<AppRouter />
				</Authentification>
			</BrowserRouter>
		</AppContext.Provider>
	);
}