import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Amplitude from "amplitude-js";

import { AppContext } from "../context";
import Loader from "../components/Loader";

export default function Download() {

	const history = useHistory();
	const { appConfig } = useContext(AppContext);
	const _user_token = localStorage.getItem("userToken");
	const PV = localStorage.getItem("pv");
	const selectedPlan = localStorage.getItem("selectedPlan");
	const [link, setLink] = useState(localStorage.getItem("downloadLink"));

	const validateToken = async () => {

		const plan = appConfig.Prices[`Paygate${PV}`][selectedPlan];

		let body = { _api_key: appConfig[appConfig.AnalyticsMode].APIkey };
		if (appConfig.AnalyticsMode === "Analytics1") {
			body._user_token = _user_token;
		} else if (appConfig.AnalyticsMode === "Analytics2") {
			body.price_id = plan.price_id;
			body.internal_user_id = _user_token;
		} else {
			history.push("/error");
			return;
		}

		try {
			const result = await (
				await fetch(appConfig[appConfig.AnalyticsMode].ValidationURL, {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(body)
				})).json();
			if (result._code === 200) {

				let token = "";
				if (appConfig.AnalyticsMode === "Analytics1") {

					token = `${appConfig[appConfig.AnalyticsMode].AmplitudePrefix}${result._data.user_id}`;

				} else if (appConfig.AnalyticsMode === "Analytics2") {

					if (result._data.external_user_id)
						token = `${appConfig[appConfig.AnalyticsMode].AmplitudePrefix}${result._data.external_user_id}`;
					else
						token = `${appConfig[appConfig.AnalyticsMode].AmplitudePrefix}${result._data.internal_user_id}`;

				} else {
					history.push("/error");
					return;
				}

				Amplitude.getInstance("main").setUserId(token);
				Amplitude.getInstance("main").logEvent("UserIDSynced");

				Amplitude.getInstance("additional").setUserId(token);
				Amplitude.getInstance("additional").logEvent("UserIDSynced");

				window.fbq("init", appConfig.Facebook.PixelID, {
					em: token,
					external_id: token
				});

				if (plan.discounted_period) {
					window.fbq("track", "Start Trial");
				}
				if ("discounted_price" in plan) {
					window.fbq("track", "Purchase", { value: Math.round(plan.discounted_price / 100), currency: "USD" });
				} else {
					window.fbq("track", "Purchase", { value: Math.round(plan.price / 100), currency: "USD" });
				}

				getLink();
			} else {
				throw new Error(`Code:${result._code}; ${result._msg}`);
			}
		} catch (err) {
			console.error(err);
			history.push("/error");
		}
	}

	const getLink = async () => {
		try {
			let result = await (
				await fetch(`${appConfig.Product.APIhost}/webflow/link`, {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ _api_key: appConfig.Product.APIkey, _user_token })
				})).json();
			if (result._code === 200) {
				setLink(result._data.link);
				localStorage.setItem("downloadLink", result._data.link);
			} else {
				throw new Error(`Code:${result._code}; ${result._msg}`);
			}
		} catch (err) {
			console.error(err);
			history.push("/error");
		}
	}

	useEffect(() => {
		Amplitude.getInstance("main").logEvent(appConfig.Download.AmplitudeEvent, appConfig.Download.AmplitudeParams);
		Amplitude.getInstance("additional").logEvent(appConfig.Download.AmplitudeEvent, appConfig.Download.AmplitudeParams);

		if (!localStorage.getItem("pv") || !localStorage.getItem("selectedPlan")) history.push("/");
		if (!localStorage.getItem("downloadLink")) validateToken();
	}, []);

	return (
		!link ? <Loader /> :
			<Container fluid className="mobile-view d-flex flex-column justify-content-between h-100 pt-5 px-0">

				<Container fluid className="px-0">
					<Row className="d-flex justify-content-center align-items-center pt-4 m-0">
						<Col xs="auto" className="px-0">
							<img className="img-fluid" src={`${appConfig.Links.imagesHost}/logo.svg`} alt="Logo" />
						</Col>
						<Col xs="auto" className="px-0">
							<h1 className="Font-Black theme-text-purple custom-fs-3 ps-2 m-0">
								QuantResearch
							</h1>
						</Col>
					</Row>

					<Row className="d-flex justify-content-center pt-5 m-0">
						<Col xs={6} className="d-flex justify-content-center px-0">
							<img className="img-fluid" src={`${appConfig.Links.imagesHost}/message-success.png`} alt="Success" />
						</Col>
					</Row>

					<Row className="pt-2 m-0">
						<Col className="px-4">
							<h2 className="Font-Heavy text-center custom-fs-1 m-0 px-3">
								{appConfig.Download.wording.text_1}
							</h2>
						</Col>
					</Row>

					<Row className="pt-4 m-0">
						<Col className="px-4">
							<p className="custom-fs-4 opacity-80 text-center px-3 m-0">
								{appConfig.Download.wording.text_2}
							</p>
						</Col>
					</Row>
				</Container>

				<Row className="pb-4 m-0">
					<Col className="px-4">
						<a href={link} className="Font-Semibold btn w-100 theme-bg-primary text-center theme-text-white custom-fs-3 rounded-20 py-3" style={{ textDecoration: "none" }}>
							{appConfig.Download.wording.text_3}
						</a>
					</Col>
				</Row>

			</Container>
	);
}