import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import Amplitude from "amplitude-js";

import { AppContext } from "../context";

export default function Error() {

	const history = useHistory();
	const { appConfig } = useContext(AppContext);

	const redirectToPayment = () => {
		history.push("/payment");
	}

	useEffect(() => {
		Amplitude.getInstance("main").logEvent(appConfig.Error.AmplitudeEvent, appConfig.Error.AmplitudeParams);
		Amplitude.getInstance("additional").logEvent(appConfig.Error.AmplitudeEvent, appConfig.Error.AmplitudeParams);
	}, []);

	return (
		<Container fluid className="mobile-view position-relative h-100 pt-5 px-0">

			<Row className="d-flex justify-content-center pt-5 m-0">
				<Col xs={6} className="d-flex justify-content-center px-0">
					<img className="img-fluid" src={`${appConfig.Links.imagesHost}/message-error.png`} alt="Error" />
				</Col>
			</Row>

			<Row className="pt-2 m-0">
				<Col className="px-4">
					<h2 className="Font-Heavy text-center custom-fs-1 m-0 px-3">
						{appConfig.Error.wording.text_1}
					</h2>
				</Col>
			</Row>

			<Row className="pt-4 m-0">
				<Col className="px-4">
					<p className="custom-fs-4 opacity-80 text-center px-3 m-0">
						{appConfig.Error.wording.text_2}
					</p>
				</Col>
			</Row>

			<Row className=" position-absolute w-100 bottom-0 pb-4 m-0">
				<Col className="px-4">
					<Button
						className="Font-Semibold text-white theme-bg-primary custom-fs-4 border-0 rounded-20 w-100 py-3"
						onClick={redirectToPayment}
					>
						{appConfig.Error.wording.text_3}
					</Button>
				</Col>
			</Row>

		</Container>
	);
}