import { lazy, Suspense, useContext, useMemo, useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Amplitude from "amplitude-js";
import StripeCheckout from "react-stripe-checkout";

import { AppContext } from "../context";
import Loader from "../components/Loader";
import ErrorToast from "../components/ErrorToast";

export default function Paygate() {

	const { appConfig } = useContext(AppContext);
	const history = useHistory();
	const PV = localStorage.getItem("pv");
	const _user_token = localStorage.getItem("userToken");
	const _app_id = appConfig[appConfig.AnalyticsMode].AppID;
	const stepsVariant = `OnboardingStepsVariant${localStorage.getItem("qn")}`;
	const stepsQuantity = appConfig[stepsVariant].length;

	const [loading, setLoading] = useState(false);
	const [stripeLabel, setStripeLabel] = useState("");
	const [selectedPlan, setSelectedPlan] = useState("Plan1");
	const [paymentProcessing, setPaymentProcessing] = useState(false);
	const [displayError, setDisplayError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const PaymentVariant = useMemo(() => lazy(() => import(`../paygateVariants/Variant${PV}`)), [PV]);

	const proceedStripe = async (token) => {

		const plan = appConfig.Prices[`Paygate${PV}`][selectedPlan];

		localStorage.setItem("selectedPaymentMethod", "stripe");
		Amplitude.getInstance("main").logEvent("Paygate Tap");
		Amplitude.getInstance("additional").logEvent("Paygate Tap");
		setPaymentProcessing(true);

		let bodyData = {
			_user_token,
			_app_id,
			price_id: plan.price_id,
			stripeToken: token.id,
			stripeTokenType: token.type,
			stripeEmail: token.email
		};

		if (localStorage.getItem("campaign")) bodyData.campaign = localStorage.getItem("campaign");
		if (localStorage.getItem("channel")) bodyData.channel = localStorage.getItem("channel");
		if (localStorage.getItem("adgroup")) bodyData.adgroup = localStorage.getItem("adgroup");
		if (localStorage.getItem("feature")) bodyData.feature = localStorage.getItem("feature");

		if (plan.discounted_period)
			bodyData.discounted_period = plan.discounted_period;
		if (plan.discounted_price)
			bodyData.discounted_price = plan.discounted_price;

		try {
			const response = await fetch(appConfig.Stripe.URL, {
				method: "POST",
				headers: { "Content-Type": "application/json", },
				body: JSON.stringify(bodyData),
			});

			if (response.ok) {
				const result = await response.json();
				if (result._code === 200) history.push("/download");
				else {
					setPaymentProcessing(false);
					setDisplayError(true);
					setErrorMessage(`${result._msg}`);
				}
			} else {
				throw (new Error(response.statusText));
			}

		} catch (err) {
			console.error(err);
			history.push("/error");
		}
	}

	const proceedPaypal = async () => {

		const plan = appConfig.Prices[`Paygate${PV}`][selectedPlan];

		localStorage.setItem("selectedPaymentMethod", "paypal");
		Amplitude.getInstance("main").logEvent("Paygate Tap");
		Amplitude.getInstance("additional").logEvent("Paygate Tap");
		setPaymentProcessing(true);

		let body = {};
		if (plan.price) body.price = plan.price;
		if (plan.discounted_price) body.sub_trial_cost = plan.discounted_price;
		if (plan.discounted_period) body.sub_trial_length = plan.discounted_period;
		if (plan.paypal_period) body.sub_billing_period = plan.paypal_period;

		body._user_token = _user_token;
		body._app_id = _app_id;
		body.link_success = `${window.location.origin}/download`;
		body.link_failure = `${window.location.origin}/error`;
		body.purchase_type = plan.paypal_type;
		body.test = appConfig.Paypal.TestMode;

		try {
			const result = await (
				await fetch(appConfig.Paypal.URL, {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(body),
				})).json();

			if (result._code === 200) {
				window.location.href = result._data.payment_link;
			} else {
				history.push("/error");
			}

		} catch (err) {
			console.error(err);
			history.push("/error");
		}
	}

	const changePlan = (paygateNum, planNum) => {
		const plan = appConfig.Prices[paygateNum][planNum];
		if (plan.discounted_period) {
			if (plan.discounted_price === 0) setStripeLabel("Start for free");
			else setStripeLabel(`Pay $${(plan.discounted_price / 100).toFixed(2)}`);
		} else {
			setStripeLabel(`Pay $${(plan.price / 100).toFixed(2)}`);
		}

		setSelectedPlan(planNum);
		localStorage.setItem("selectedPlan", planNum);
	}

	useEffect(() => {
		localStorage.setItem("selectedPlan", selectedPlan);

		if (localStorage.getItem("downloadLink")) history.push("/download");
		else if (+sessionStorage.getItem("currentStep") < (stepsQuantity - 1)) history.push("/");
		else {
			Amplitude.getInstance("main").logEvent(appConfig[`Paygate${PV}`].AmplitudeEvent, appConfig[`Paygate${PV}`].AmplitudeParams);
			Amplitude.getInstance("additional").logEvent(appConfig[`Paygate${PV}`].AmplitudeEvent, appConfig[`Paygate${PV}`].AmplitudeParams);
		}
	}, []);

	const Checkout = () =>
		<Container fluid className="px-0">
			<Row className="m-0">
				<Col className="px-0">
					<Button
						className="Font-Semibold paypal-button custom-fs-4 border-0 w-100 py-3"
						onClick={proceedPaypal}
					>
						<img src={`${appConfig.Links.imagesHost}/paypal.svg`} className="img-fluid" alt="paypal" />
					</Button>
				</Col>
			</Row>

			{appConfig[`Paygate${PV}`].showDivider &&
				<div className="separator mt-4">
					<p className="Font-Bold custom-fs-3 m-0">
						OR
					</p>
				</div>
			}

			<Row className="pt-4 m-0">
				<Col className="px-0">
					<StripeCheckout
						token={proceedStripe}
						name={appConfig.Stripe.Name}
						description={appConfig.Stripe.Description}
						image={appConfig.Stripe.Image}
						panelLabel={stripeLabel}
						stripeKey={appConfig.Stripe.Key}
						closed={() => setLoading(false)}
						shippingAddress
						billingAddress
						zipCode
					>
						<Button className="d-flex payment-button align-items-center justify-content-center Font-Semibold custom-fs-4 border-0 w-100 py-3" onClick={() => setLoading(true)}>
							<img src={`${appConfig.Links.imagesHost}/payment-card-icon.svg`} className="img-fluid" alt="card" />
							<span className="ps-2">
								{appConfig.Stripe.ButtonText}
							</span>
						</Button>
					</StripeCheckout>
				</Col>
			</Row>
		</Container>

	return (
		<Suspense fallback={<Loader />}>
			<div className="h-100 position-relative mobile-view">

				<ErrorToast
					className="position-fixed top-0 w-100 mobile-view bg-danger bg-gradient rounded-0 border-0 shadow-none z-index-5 px-0"
					onClose={() => setDisplayError(false)}
					show={displayError}
					delay={10000}
					autohide
				>
					<p className="Font-Bold text-center text-white py-2 m-0">
						{errorMessage}
					</p>
				</ErrorToast>

				{(loading || paymentProcessing) && <Loader />}

				<PaymentVariant selectedPlan={selectedPlan} changePlan={changePlan}>
					<Checkout />
				</PaymentVariant>

			</div>
		</Suspense>
	);
}