import { lazy, Suspense, useMemo, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Offcanvas } from "react-bootstrap";
import Amplitude from "amplitude-js";

import { AppContext } from "../context";

export default function Welcome() {

	const history = useHistory();
	const { appConfig } = useContext(AppContext);
	const variant = +new URLSearchParams(window.location.search).get("var") || appConfig.Welcome.AvailableVariants[0];
	const WelcomeVariant = useMemo(() => lazy(() => import(`../welcomeVariants/Variant${variant}`)), [variant]);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
		if (localStorage.getItem("downloadLink")) history.push("/download");
		if (!(`WelcomeVar${variant}` in appConfig)) history.push("/");
		else {
			Amplitude.getInstance("main").logEvent(appConfig.Welcome.AmplitudeEvent, { "var": variant, ...appConfig.Welcome.AmplitudeParams });
			Amplitude.getInstance("additional").logEvent(appConfig.Welcome.AmplitudeEvent, { "var": variant, ...appConfig.Welcome.AmplitudeParams });
		}
	}, [])

	return (
		<Container fluid className={`welcome-${variant} mobile-view h-100 py-4 px-3`}>

			<Offcanvas className="custom-offcanvas py-5" show={show} placement="end" onHide={handleClose}>
				<Offcanvas.Header className="py-0" closeButton />
				<Offcanvas.Body className="pt-5 px-4">

					<Row className="m-0">
						<Col className="px-0">
							<a href={appConfig.Links.privacyPolicy} className="text-decoration-none text-white custom-fs-3">
								{appConfig.Welcome.wording.text_1}
							</a>
						</Col>
					</Row>

					<Row className="m-0">
						<Col className="px-0">
							<a href={appConfig.Links.termsOfUse} className="text-decoration-none text-white custom-fs-3">
								{appConfig.Welcome.wording.text_2}
							</a>
						</Col>
					</Row>

					<Row className="m-0">
						<Col className="px-0">
							<a href={appConfig.Links.contact} className="text-decoration-none text-white custom-fs-3">
								{appConfig.Welcome.wording.text_3}
							</a>
						</Col>
					</Row>

				</Offcanvas.Body>
			</Offcanvas>

			<Suspense fallback={<></>}>
				<WelcomeVariant
					imagesHost={appConfig.Links.imagesHost}
					config={appConfig[`WelcomeVar${variant}`]}
					showOffcanvas={handleShow}
					links={appConfig.Links}
				/>
			</Suspense>

		</Container>
	);
}