import { lazy, Suspense, useMemo, useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import Amplitude from "amplitude-js";

import { AppContext } from "../context";

export default function Onboarding() {

	const history = useHistory();
	const { appConfig } = useContext(AppContext);
	const _api_key = appConfig.Product.APIkey;
	const stepsVariant = `OnboardingStepsVariant${localStorage.getItem("qn")}`;
	const stepsQuantity = appConfig[stepsVariant].length;
	const defaultStep = (+sessionStorage.getItem("currentStep") && +sessionStorage.getItem("currentStep") < stepsQuantity) ? +sessionStorage.getItem("currentStep") : 0;
	const [stepNum, setStepNum] = useState(defaultStep);

	const lazyWithPreload = (factory) => {
		const Component = lazy(factory);
		Component.preload = factory;
		return Component;
	}

	const OnboardingStep = useMemo(() => lazy(() => import(`../onboardingSteps/${appConfig[stepsVariant][stepNum]}`)), [stepNum]);
	const ComponentToPreload = stepNum < (stepsQuantity - 1) && lazyWithPreload(() => import(`../onboardingSteps/${appConfig[stepsVariant][stepNum + 1]}`));

	const logAnalyticsEvent = async (key, value) => {
		try {
			const result = await (
				await fetch(`${appConfig.Product.APIhost}/users/set`, {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ _api_key, _user_token: localStorage.getItem("userToken"), [key]: value })
				})).json();
			if (result._code !== 200) throw new Error(`Code:${result._code}; ${result._msg}`);
		} catch (err) {
			console.error(err);
		}
	}

	const handlePrevious = (stepsToGoBack) => {
		if (stepsToGoBack) {
			if (stepNum - +stepsToGoBack < 0) setStepNum(0);
			else setStepNum(stepNum - +stepsToGoBack);
		} else {
			if (stepNum > 0) setStepNum(stepNum - 1);
		}
	}

	const handleNext = (valueToSubmit, stepsToSkip) => {
		if (appConfig[appConfig[stepsVariant][stepNum]].AnalyticsEvent)
			logAnalyticsEvent(appConfig[appConfig[stepsVariant][stepNum]].AnalyticsEvent, valueToSubmit);

		if (appConfig[appConfig[stepsVariant][stepNum]].AdditionalAnalyticsEvent && appConfig[appConfig[stepsVariant][stepNum]].AdditionalAnalyticsEvent === "course_ids")
			logAnalyticsEvent(appConfig[appConfig[stepsVariant][stepNum]].AdditionalAnalyticsEvent, [valueToSubmit]);

		if (valueToSubmit !== null && valueToSubmit !== undefined)
			localStorage.setItem(`__${appConfig[stepsVariant][stepNum]}`, valueToSubmit);

		if (stepNum < stepsQuantity - 1) {
			if (stepsToSkip) setStepNum(stepNum + stepsToSkip);
			else setStepNum(stepNum + 1);
		} else {
			setStepNum(stepsQuantity - 1);
			history.push("/result");
		}
	}

	useEffect(() => {
		if (localStorage.getItem("downloadLink")) history.push("/download");

		sessionStorage.setItem("currentStep", stepNum);

		if (appConfig[appConfig[stepsVariant][stepNum]] && appConfig[appConfig[stepsVariant][stepNum]] !== "Testing") {
			Amplitude.getInstance("main")
				.logEvent(
					appConfig[appConfig[stepsVariant][stepNum]].AmplitudeEvent,
					appConfig[appConfig[stepsVariant][stepNum]].AmplitudeParams
				);

			Amplitude.getInstance("additional")
				.logEvent(
					appConfig[appConfig[stepsVariant][stepNum]].AmplitudeEvent,
					appConfig[appConfig[stepsVariant][stepNum]].AmplitudeParams
				);
		}

		if (stepNum < (stepsQuantity - 2)) ComponentToPreload.preload();
	}, [stepNum])

	return (
		<SwitchTransition>
			<CSSTransition
				key={stepNum}
				timeout={500}
				classNames="step"
			>
				<Suspense fallback={<></>}>
					<div className="mobile-view h-100">
						<OnboardingStep
							previous={handlePrevious}
							next={handleNext}
							imagesHost={appConfig.Links.imagesHost}
							config={appConfig[appConfig[stepsVariant][stepNum]]}
							currentStep={stepNum}
							stepsQuantity={stepsQuantity}
						/>
					</div>
					{stepNum < (stepsQuantity - 1) &&
						<Suspense fallback={null}>
							<div hidden={true}>
								<ComponentToPreload
									previous={handlePrevious}
									next={handleNext}
									imagesHost={appConfig.Links.imagesHost}
									config={appConfig[appConfig[stepsVariant][stepNum + 1]]}
									currentStep={stepNum + 1}
									stepsQuantity={stepsQuantity}
									preloading
								/>
							</div>
						</Suspense>
					}
				</Suspense>
			</CSSTransition>
		</SwitchTransition>
	);
}